const FirebaseConfig = {
  apiKey: "AIzaSyAQE7b5-pNyP0lMckqnIHfT_jbXRScGdjM",
  authDomain: "quickreportsgd.firebaseapp.com",
  projectId: "quickreportsgd",
  storageBucket: "quickreportsgd.appspot.com",
  messagingSenderId: "284546341236",
  appId: "1:284546341236:web:06be13ef0cce271e163421",
  measurementId: "G-NDPBZ2CQPJ"
};

export default FirebaseConfig

