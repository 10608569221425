import React from "react";
import { connect } from "react-redux";

import Loading from "components/shared-components/Loading";

import AppViews from "views/app-views";
import { useThemeSwitcher } from "react-css-theme-switcher";

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return <AppViews />;
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
